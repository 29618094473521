<template>
  <div>
    <CCard class="p-4">
      <CRow>
        <CCol lg="6">
          <h5>Overtime/Late Details</h5>
        </CCol>
       
      </CRow>

      <hr />
      <!-- <CRow class="p-2 mb-1 bg-custom-light text-dark rounded"></CRow> -->
      <CRow v-for="item in otLateInfo" class="p-2 mb-1 bg-light text-dark rounded">       
      <CCol lg="4">
          <span >
            <h2 class="mb-0 text-primary">{{item.employee_name}}</h2>
            <p>
              <em>{{item.personnel_type}}</em><br />
              <strong style="text-transform:uppercase; font-size:12px;color:green">{{item.status}}</strong>
            </p>
          </span>
        </CCol>
        <CCol lg="4">
          <p style="margin-top: 15px">
            <span style="margin-right: 101px">OT Total: </span> {{item.overtime_total | number_format('currency')}}
            <br/>
            <span style="margin-right: 20px">Overtime Credit Total:</span> {{item.overtime_credit_total | number_format('currency')}}
            <br />
           <span
            style="margin-right: 60px ; font-size:18px; font-weight: 600;" >Balance OT:  </span> {{item.balance_ot  | number_format('currency')}} 
          
          </p>
        </CCol>
     
      <CCol lg="4">
          <p style="margin-top: 15px">
            <span style="margin-right: 93px">Late Total:</span> {{item.late_total | number_format('currency')}}
            <br />
            <span style="margin-right: 25px">Late Deduction Total: </span> {{item.late_deduction_total | number_format('currency')}}
            <br />
            <span style="margin-right: 51px ; font-size:18px; font-weight:600" >Balance Late:  </span> {{item.balance_late  | number_format('currency')}} 
          </p>
        </CCol>
      </CRow>
     
      <hr />
      <CRow>
        <CCol lg="12">
          <CRow>
            <CCol lg="9" class="form-inline">
              <div>
              <h6>Date From</h6>
              <div class="form-inline">
                <Datepicker
                  input-class="form-control bg-white"
                  v-model="filter_date_from"
                  
                >
                </Datepicker>
                <i class="fa fa-calendar ml-2"></i>
              </div>
            </div>
              <div class="ml-3">
              <h6>Date From</h6>
              <div class="form-inline">
                <Datepicker
                  input-class="form-control bg-white"
                  v-model="filter_date_to"
                  
                >
                </Datepicker>
                <i class="fa fa-calendar ml-2"></i>
              </div>
            </div>

              <div class="ml-3 mt-4">
							<CButton 
							size="sm"
							color="info" @click="filterList" 
							>
							<i class="fa fa-filter"></i> Filter
							</CButton>	

						</div>	
            </CCol>	
           
            <CCol lg="3" class="mt-3">
          <CButton
            v-if="config.getPermission('ot_late').create"
            size="sm"
            style="margin-right: 10px"
            color="success"
            @click="addModal()"
            class="float-lg-right"
            ><i class="fa fa-plus"></i>Add OT/Late
          </CButton>
        </CCol>
         
      
          </CRow>
        </CCol>
        
      </CRow>
      <br>
      <CRow>
        <!-- <CCol lg="12" class="text-center" v-if="isLoading">
          <div class="spinner-border m-5" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </CCol> -->
        <CCol lg="12">
          <div class="table-responsive-sm table-responsive-md">
            <CDataTable
             :loading="isLoading"
              :items="dataList.data"
              :fields="fields"
              hover
              outlined
              pagination
              itemperPage="10"
              itemsPerPageSelect
  
            >
              <template #status="{ item }">
                <td
                  style="padding: 5px; width: 100px; text-transform: uppercase"
                >
                  <CBadge
                    style="margin-top: 10px"
                    :color="getBadge(item.status)"
                    >{{ item.status }}</CBadge
                  >
                </td>
              </template>
              <template #action="{ item }">
                <td style="padding: 5px; width: 100px">
                  <CDropdown toggler-text="Select" size="sm" color="info">
                   
                    <CDropdownItem
                      v-if="config.getPermission('ot_late').update"
                      @click="updateModal(item)"
                      ><i class="fa fa-pencil-square-o">
                        Update
                      </i></CDropdownItem
                    >
                    <CDropdownItem
                    v-if="config.getPermission('ot_late').delete"
                      @click="deleteItem(item)"
                      ><i class="fa fa-trash"> Delete</i></CDropdownItem
                    >
                  </CDropdown>
                </td>
              </template>
            </CDataTable>
            <!-- <CRow v-for="item in otLateInfo">
              <CCol lg="2"><strong>TOTAL</strong></CCol>
              <CCol lg="2"
                ><strong style="margin-left: 46px">{{ 100.00 }}</strong></CCol
              >
              <CCol lg="2"
                ><strong style="margin-left: 32px">{{ 200.00 }}</strong></CCol
              >
              <CCol lg="2"
                ><strong style="margin-left: 20px">{{ 100.00 }}</strong></CCol
              >
              <CCol lg="2"
                ><strong style="margin-left: 39px">{{ 100.00 }}</strong></CCol
              >
            </CRow> -->
            <br>
           
          </div>
        </CCol>
      </CRow>

      
    </CCard>
    <!-- ADD ENTRY-->
    <CModal
      :title="title"
      :show.sync="formModal"
      @close="closeModal()"
      color="info"
    >
      <form @submit.prevent="submit">
        <CRow>
          <CCol class="col-md-12">
            <div class="form-group">
              <small for="type"
                >Type<span class="text-danger"> *</span>
              </small>
              <v-select
                label="label"
                :options="[
                  { label: 'Overtime', value: 'overtime', },
                  { label: 'Late', value: 'late', },
                  { label: 'Overtime Credit', value: 'overtime_credit', },
                  { label: 'Late Deduction', value: 'late_deduction', },
                ]"
                :reduce="(item) => item.value"                
                v-model="overtimeLateParam.type"                
              >
              <template #search="{attributes, events}">
											<input
												class="vs__search"
												:required="!overtimeLateParam.type"
												v-bind="attributes"
												v-on="events"
											/>
										</template>
              </v-select>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="12">
            <small
              >Amount
              <span class="text-danger">* </span>
            </small>
            <CInput
              type="text"
              v-model=" overtimeLateParam.amount"
              @keypress="isNumber($event)"
              required="true" class="mb-0" :class="{'has-error' : hasError}"/>
              <small class="text-danger" v-show="hasError">
                Amount should not be greater than with 
                {{ overtimeLateParam.type == 'overtime_credit' ? 'balance OT' : 'balance LATE' }} 
              </small>
          
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="12">
            <small>Date <span class="text-danger">* </span></small>
            <Datepicker
              input-class="form-control bg-white"
              :typeable="true"
              class="mb-2"
              v-model="overtimeLateParam.transaction_date"
              required
            >
            </Datepicker>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="12">
            <small class="mr-2">Remarks</small>
            <textarea
              :disabled="is_disabled"
              class="form-control mb-2"
              v-model="overtimeLateParam.remarks"
            ></textarea>
          </CCol>
        </CRow>
        <div slot="footer" class="w-100 mt-3">
          <template>
            <CButton
              type="submit"
              style="border-radius: 0.2rem; color: white"
              color="info"
              class="ml-1 mr-1 float-right"
              size="sm"
            >
              Save
            </CButton>
          </template>
          <CButton
            style="border-radius: 0.2rem; color: white"
            color="danger"
            class="ml-1 mr-1 float-right"
            @click="closeModal()"
            size="sm"
          >
            Close
          </CButton>
        </div>
      </form>
      <div slot="footer" class="w-100"></div>
    </CModal>
  
   
  </div>
</template>
<script>
import config from "../../config.js";
import axios from "../../axios";
import Swal from "sweetalert2";
import Search from "./search";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";

export default {
  mounted() {
    // this.getOTLateListFilter();
    this.defaultDateFrom();
    this.getOTLateList();
    this.getPersonnel();
    this.getPersonel_OTLateInfo();    

    // axios.post(`${config.api_path}/otlate/process`,{
    //      flag: 2, // 0 insert, 1 update, 2 delete
    //     type : 'overtime',
    //     amount : 50,
    //     transaction_date : '2022-09-28',
    //     remarks : 'deduct',
    //     personnel_id : this.$route.params.id,
    //     id : 7
    // })
  },
  filters: {
    formatDate(val) {
      if (val) return moment.unix(val).format("MMMM DD, YYYY");
      return "";
    },
  },
  watch: {
		
	},
  computed: {
    
    is_disabled() {
      return this.mode.view;
    },
    
    hasError() {
        let type = this.overtimeLateParam.type;
        let inputted = this. overtimeLateParam.amount ?? 0;
        let balance = 0;
        
        if(!this.otLateInfo.length) return false;

        if(type == 'overtime_credit') { //cash advance
          balance = this.otLateInfo[0].balance_ot ?? 0;
          if(parseFloat(inputted) > parseFloat(balance)) {
            return true;
          }
          return false;
        }
        else if(type == 'late_deduction') { //charges
          balance = this.otLateInfo[0].balance_late ?? 0;
          if(parseFloat(inputted) > parseFloat(balance)) {
            return true;
          }
          return false;
        }
    }
  },
  data() {
    return {
      title: '',
      filter_date_to:null,
      filter_date_from:null,
      flag: 0,
      isLoading: false,
      config,
      dataParams: {
        type: "all",
      },
      formModal: false,
      deductionModal: false,
      otLateInfo: [],
      dataList: [],
      depotName: "",
      fields: [
      
      {
          key: "transaction_date",
          label: "Date",
        },
        // {
        //   key: "type",
        //   label: "Type",
        // },
        {
          key: "emp_overtime",
          label: "Overtime",
        },
        {
          key: "emp_late",
          label: "Late",
        },

        {
          key: "emp_overtime_credit",
          label: "Overtime Credit",
        },
        {
          key: "emp_late_deduction",
          label: "Late Deduction",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      // viewOvertimeLate: [
      //   {
      //     overtime_date: "April 26, 2022",
      //     emp_overtime: "100.00",
      //     emp_late: "",
      //     emp_overtime_credit: "70.00",
      //     emp_overtime_late_deduction: "",
      //   },
      //   {
      //     overtime_date: "April 27, 2022",
      //     emp_overtime: "",
      //     emp_late: "50.00",
      //     emp_overtime_credit: "",
      //     emp_overtime_late_deduction: "40.00",
      //   },
      // ],
      currentPage: 1,
      limit:10,
      selectedRow: {},
      showCollapse: false,


      personnelList: {
        data: [],
      },

      showViewModal: false,
     
      overtimeLateParam: {
        type : "",
        amount: "",
        transaction_date: null,        
        remarks : "",
        id : null        
      },
      mode: {
        view: false,
        new: true,
        edit: false,
      },
      dataListHolder: []
    };
  },
  name: "Tables",
  components: { Search, Datepicker, vSelect },
  methods: {
    // typeChanged(ev) {
    //   console.log(ev)
    //   if(ev == 'all') {
    //     this.dataList.data = [...this.dataListHolder];
    //   }
    //   else {
    //     this.dataList.data = this.dataListHolder.filter(item => item.type_holder == ev)
    //   }
    // },
    getPersonel_OTLateInfo() {
      this.$showLoading(true)
      axios.get(config.api_path+'/otlate/personnel/info',{
				params : {
					id : this.$route.params.id
				}
			}).then((res) => {
        this.$showLoading(false)
          this.otLateInfo = res.data.data;                             
        })
    },
    download() {
      this.getOTLateList("download");
    },


    //add otlate
     // axios.post(`${config.api_path}/otlate/process`,{
    //      flag: 2, // 0 insert, 1 update, 2 delete
    //     type : 'overtime',
    //     amount : 50,
    //     transaction_date : '2022-09-28',
    //     remarks : 'deduct',
    //     personnel_id : this.$route.params.id,
    //     id : 7
    // })

    submit() {      
        if(this.overtimeLateParam.type == null) {
          Swal.fire({
              title: 'warning',
              text: "Please fill out the type field.",
              icon: 'warning', 
            })	
        }else {
          this.$showLoading(true)      
        axios.post(config.api_path+'/otlate/process',{          
          flag: this.overtimeLateParam.id != null ? 1:0, // 0 insert, 1 update, 2 delete
          type : this.overtimeLateParam.type,
          amount : this.overtimeLateParam.amount,
          transaction_date : moment(this.overtimeLateParam.transaction_date).format("y-M-D"),
          remarks : this.overtimeLateParam?.remarks ?? null,          
          personnel_id : this.$route.params.id,
          id : this.overtimeLateParam.id                     
          
        }).then(res => {
          this.$showLoading(false)
          this.formModal = false;          
          this.getOTLateList();  
          this.getPersonel_OTLateInfo();   
          this.ResetovertimeLateParam();   
            	
        })
        .catch(err => {
          this.$showLoading(false)
        })  
        if(this.overtimeLateParam.id != null) {
          Swal.fire({
              title: 'Success',
              text: "Overtime / Late successfully updated.",
              icon: 'success', 
            })	
        } else {
          Swal.fire({
              title: 'Success',
              text: "Overtime / Late successfully added.",
              icon: 'success', 
            })	           
        }    
        }            		       
    },

    addModal() {
      this.setMode("new");
      this.formModal = !this.formModal;
      this.title = "Add OT/Late";      
    },
  
    updateModal(item){
      console.log(item);
      this.formModal = true; 
      this.title = "Update OT/Late";
      this.selectedRow = item;
      this.overtimeLateParam = {...item};       
      // if(this.overtimeLateParam.type == "Overtime") {
      //   this.overtimeLateParam.type = 'overtime';
      // } else if (this.overtimeLateParam.type == "Late"){
      //   this.overtimeLateParam.type = 'late';
      // } else if (this.overtimeLateParam.type == "Overtime Credit"){
      //   this.overtimeLateParam.type = 'overtime_credit';
      // } else if (this.overtimeLateParam.type == "Late Deduction"){
      //   this.overtimeLateParam.type = 'late_deduction';
      // }
      
                      
    },
   
    setModeDeduction(type) {
      this.mode = {
        view: false,
        edit: false,
        new: false,
      };
      this.mode[type] = true;
    },
    setMode(type) {
      this.mode = {
        view: false,
        edit: false,
        new: false,
      };
      this.mode[type] = true;
    },
    
    viewPayment() {
    // this.$router.push('/data/odometer/'+this.$route.params.id)
		window.open(`#/main/view_personnel_payment/${this.$route.params.id}`, '_blank');
	},
	
  
   
    closeModal() {
      console.log("oyet");
      this.formModal = false;      
      this.reloadData();
      this.ResetovertimeLateParam();
    },
    reloadData() {
      this.getOTLateList();
    },
    updatePage(data) {
      this.currentPage = data;
      this.getOTLateList();
    },

    search(event) {
      this.filter = event;
      this.getOTLateList();
    },

    ResetovertimeLateParam() {                  
         this.overtimeLateParam.type = "",
         this.overtimeLateParam.amount= "",
         this.overtimeLateParam.transaction_date= null,         
         this.overtimeLateParam.remarks = "",
         this.overtimeLateParam.id = null
	},
   
    getPersonnel() {
      axios
        .get(config.api_path + "/reference/personnel-list")
        .then((response) => {
          this.personnelList = response.data;
        });
    },
    filterList() {
      this.getOTLateList()
     
    //   axios.get(config.api_path+'/otlate/personnel/list',{
    //   params : {
    //     id : this.$route.params.id,
    //     date_from: moment(this.filter_date_from).format("y-M-D"),
    //     date_to: moment(this.filter_date_to).format("y-M-D")
    //   }
    // }).then((response) => {
    //   this.$showLoading(false)
    //     this.dataList = response.data;
    //     this.dataList.data = this.dataList.data.map(item => {
    //         item.transaction_date = item.transaction_date ? moment(item.transaction_date ).format('MM/DD/YYYY') : '';   
    //         item.emp_overtime = item.emp_overtime ? this.$options.filters.number_format(item.emp_overtime, 'currency', 2) : ''; 
    //         item.emp_overtime_credit = item.emp_overtime_credit ? this.$options.filters.number_format(item.emp_overtime_credit, 'currency', 2) : '';
    //         item.emp_late = item.emp_late ? this.$options.filters.number_format(item.emp_late, 'currency', 2) : '';  
    //         item.emp_late_deduction = item.emp_late_deduction ? this.$options.filters.number_format(item.emp_late_deduction, 'currency', 2) : '';                
    //         return item;
    //     });
    //     this.dataListHolder = [...this.dataList.data];        
    // });  
    },
    defaultDateFrom(){          
      var date = new Date();
      this.date_to = this.date = moment(date).format('MMM DD YYYY');
      date.setDate(date.getDate() - 30);
      this.date_from = this.date = moment(date).format('MMM DD YYYY');
      this.filter_date_from = this.date_from;
      this.filter_date_to = this.date_to;
      this.filterList();
    //   this.$showLoading(true)
    //   axios.get(config.api_path+'/otlate/personnel/list',{
    //   params : {
    //     id : this.$route.params.id,
    //     date_from: moment(this.filter_date_from).format("y-M-D"),
    //     date_to: moment(this.filter_date_to).format("y-M-D")
    //   }
    // }).then((response) => {
    //   this.$showLoading(false)
    //     this.dataList = response.data;
    //     this.dataList.data = this.dataList.data.map(item => {
    //         item.transaction_date = item.transaction_date ? moment(item.transaction_date ).format('MM/DD/YYYY') : '';  
    //         item.emp_overtime = item.emp_overtime ? this.$options.filters.number_format(item.emp_overtime, 'currency', 2) : ''; 
    //         item.emp_overtime_credit = item.emp_overtime_credit ? this.$options.filters.number_format(item.emp_overtime_credit, 'currency', 2) : '';
    //         item.emp_late = item.emp_late ? this.$options.filters.number_format(item.emp_late, 'currency', 2) : '';  
    //         item.emp_late_deduction = item.emp_late_deduction ? this.$options.filters.number_format(item.emp_late_deduction, 'currency', 2) : '';                   
    //         return item;
    //     });
    //     this.dataListHolder = [...this.dataList.data];        
    // });  
    },
    getOTLateList() {
      
      this.$showLoading(true)
      axios.get(config.api_path+'/otlate/personnel/list',{
      params : {
        id : this.$route.params.id,
        date_from: moment(this.filter_date_from).format("y-M-D"),
        date_to: moment(this.filter_date_to).format("y-M-D")
      }
    }).then((response) => {
      this.$showLoading(false)
        this.dataList = response.data;
        this.dataList.data = this.dataList.data.map(item => {
            item.transaction_date = item.transaction_date ? moment(item.transaction_date ).format('MM/DD/YYYY') : '';   
            item.emp_overtime = item.emp_overtime ? this.$options.filters.number_format(item.emp_overtime, '', 2) : ''; 
            item.emp_overtime_credit = item.emp_overtime_credit ? this.$options.filters.number_format(item.emp_overtime_credit,'', 2) : '';
            item.emp_late = item.emp_late ? this.$options.filters.number_format(item.emp_late,'', 2) : '';  
            item.emp_late_deduction = item.emp_late_deduction ? this.$options.filters.number_format(item.emp_late_deduction,'', 2) : '';                    
            return item;
        });
        this.dataListHolder = [...this.dataList.data];        
    });      
    },
   
    deleteItem(item) {     
      Swal.fire({
        icon: "warning",
        title: "Are you sure you want to delete this entry ?",
        showCancelButton: true,
        confirmButtonText: `Yes, delete`,
        reverseButtons: true,
        confirmButtonColor:'red'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(config.api_path+'/otlate/process',{
                flag: 2, // 0 insert, 1 update, 2 delete
                type : this.overtimeLateParam.type,
                amount : this.overtimeLateParam.amount,
                transaction_date : this.overtimeLateParam.transaction_date,
                remarks : this.overtimeLateParam.remarks,
                personnel_id : this.$route.params.id,
                id : item.id                         
          }).then(res => {
            this.$showLoading(false)
            this.formModal = false;
            this.getOTLateList();
          this. getPersonel_OTLateInfo();   
          this.ResetovertimeLateParam();      

            Swal.fire({
              title: 'Success',
              text: "Overtime / Late successfully deleted.",
              icon: 'success', 
            })	
          })
          .catch(err => {
            this.$showLoading(false)
          })          
        }
      });
    },
  },
};


</script>
